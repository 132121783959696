// src/components/ScreenRecorder.js
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { v4 } from "uuid";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Video = styled.video`
  margin-bottom: 40px;
  height: 60vh;
  min-height: 400px;
  border-radius: 20px;
  border: 2px solid #475da8;
`;

const Button = styled.button`
  padding: 10px 20px;
  margin: 5px;
  font-size: 16px;
  border-radius: 20px;
  border: 1px solid black;
  color: white;
  background: #475da8;
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  width: 200px;
`;

const ScreenRecorder = () => {
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [error, setError] = useState([]);
  const [mediaStream, setMediaStream] = useState(null);
  const [externalAudio, setExternalAudio] = useState(null);
  const [internalAudio, setInternalAudio] = useState(null);
  const videoRef = useRef(null);
  const screenReelTimeRef = useRef(null);
  const mediaRecorderRef = useRef(null);

  const startRecording = async () => {
    setError();
    if (videoRef.current) videoRef.current.removeAttribute("src");
    // Get display media stream
    const displayMediaOptions = {
      video: {
        cursor: "always",
      },
      audio: true,
    };

    try {
      const screenStream = await navigator.mediaDevices.getDisplayMedia(
        displayMediaOptions
      );

      // Get user media stream for microphone
      let audioStream;
      if (externalAudio)
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: false,
          },
        });

      // Combine screen stream and microphone audio stream
      const combinedStream = new MediaStream([
        ...screenStream.getVideoTracks(),
        ...(internalAudio ? screenStream.getAudioTracks() : []),
        ...(externalAudio ? audioStream.getAudioTracks() : []),
      ]);
      setMediaStream(combinedStream);

      // Set up MediaRecorder
      const mediaRecorder = new MediaRecorder(combinedStream, {
        mimeType: "video/webm; codecs=vp9",
      });
      mediaRecorderRef.current = mediaRecorder;

      setRecordedChunks([]);

      combinedStream
        .getTracks()
        .forEach((track) => (track.onended = stopRecording));

      mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);
          stopRecording();
        }
      };

      mediaRecorder.start();
      setRecording(true);
    } catch (err) {
      setError("Recording has ended.");
    }
  };

  window.addEventListener("beforeunload", function (e) {
    e.preventDefault();
    e.returnValue = "";
    return "Are you sure you want to leave? Your changes may not be saved.";
  });

  const stopRecording = () => {
    mediaRecorderRef.current.stop();
    setRecording(false);
    try {
      mediaStream.getTracks().forEach((track) => track.stop());
    } catch (err) {
      //
    }
    window.focus();
    setMediaStream(null);
  };

  const downloadRecording = () => {
    const url = videoRef.current.src;
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = `${v4()}.webm`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    setRecordedChunks([]);
  };

  useEffect(() => {
    if (recordedChunks.length > 0) {
      const blob = new Blob(recordedChunks, { type: "video/webm" });
      const url = URL.createObjectURL(blob);
      videoRef.current.src = url;
    }
  }, [recordedChunks]);

  useEffect(() => {
    if (mediaStream && screenReelTimeRef)
      screenReelTimeRef.current.srcObject = mediaStream;
  }, [mediaStream]);

  return (
    <Container>
      <div
        onClick={() => window.open("https://buymeacoffee.com/screen", "_blank")}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          background: "#FFDD04",
          borderRadius: "20px",
          position: "fixed",
          top: "20px",
          right: "10px",
          cursor: "pointer",
        }}
      >
        <img
          src="/buy.png"
          alt="..."
          style={{ marginRight: "5px", marginLeft: "10px" }}
          width={"40px"}
        />
        <p style={{ marginRight: "20px", fontWeight: "bold" }}>
          Buy me a coffee
        </p>
      </div>
      {recordedChunks.length > 0 ? (
        <Video ref={videoRef} controls playsInline></Video>
      ) : recording ? (
        <video
          style={{
            marginBottom: "40px",
            height: "60vh",
            minHeight: "400px",
            borderRadius: "20px",
            border: "2px solid #475da8",
          }}
          autoPlay={recording}
          muted={recording}
          ref={screenReelTimeRef}
        ></video>
      ) : (
        <div
          style={{
            width: "70%",
            marginBottom: "40px",
            height: "60vh",
            minHeight: "200px",
            background: "#E1E2F2",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "20px",
            border: "2px solid #475DA8",
          }}
        >
          <img
            alt="..."
            style={{ minWidth: "300px", width: "40%" }}
            src="/action.gif"
          />
        </div>
      )}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <b style={{ marginRight: "10px" }}>Internal audio</b>
        <label className="switch">
          <input
            onChange={(e) => setInternalAudio(e.target.checked)}
            disabled={recording}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>

        <b style={{ marginRight: "10px", marginLeft: "20px" }}>
          External audio
        </b>
        <label className="switch">
          <input
            onChange={(e) => setExternalAudio(e.target.checked)}
            disabled={recording}
            type="checkbox"
          />
          <span className="slider round"></span>
        </label>
      </div>
      <div>
        {!recording && (
          <Button onClick={startRecording}>Start Recording</Button>
        )}
        {recording && (
          <Button
            onClick={stopRecording}
            style={{ background: "#C22C2C", border: "none" }}
          >
            Stop Recording
          </Button>
        )}
        {recordedChunks.length > 0 && (
          <Button onClick={downloadRecording}>Download</Button>
        )}
      </div>
      {error ? <label style={{ color: "red" }}>{error}</label> : <></>}
    </Container>
  );
};

export default ScreenRecorder;
