import React from "react";
import ScreenRecorder from "./components/ScreenRecorder";

function App() {
  return (
    <div className="App">
      <center>
        <img
          src="/logo.png"
          style={{ marginTop: "40px" }}
          width={"60px"}
          height={"60px"}
          alt={"...."}
        />
        <h2 style={{ marginTop: "5px", fontSize: "3vh" }}>
          EasyScreen Recorder
        </h2>
      </center>
      <ScreenRecorder />
    </div>
  );
}

export default App;
